import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { FaEllipsisH } from 'react-icons/fa';

export default ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Projects Page"
        keywords={[
          `gratuk`,
          `Gratuk Technologies`,
          `project management`,
          `scientific method`
        ]}
      />
      <section className="bg-brand pb-4 my-0 font-sans min-h-screen pt-16">
        <h1 className="font-display uppercase tracking-widest text-3xl text-gray-200 max-w-full text-center pb-16 m-0">
          Featured Projects
        </h1>
        <div className="max-w-5xl m-auto flex flex-wrap items-center justify-start">
          {data.allMarkdownRemark.edges.map(({ node }, index) => (
            <div
              key={index}
              className="w-full md:w-1/2 flex flex-col mb-8 px-3"
            >
              <div className="overflow-hidden bg-gray-200 rounded-lg shadow hover:shadow-raised hover:translateY-6px transition">
                <Link to={node.fields.slug}
                    aria-label="Read more about the featured project">
                  <Img
                    className="w-full"
                    fluid={node.frontmatter.cover.path.childImageSharp.fluid}
                    alt={node.frontmatter.cover.desc}
                  />
                </Link>
                <div className="p-6 flex flex-col justify-between ">
                  <h3 className="font-display p-0 font-medium text-grey-darkest pb-2 text-lg">
                    {node.frontmatter.title}
                  </h3>
                  <p className="leading-relaxed">{node.frontmatter.extract}</p>
                  <Link to={node.fields.slug} className="text-right"
                      aria-label="Read more about the featured project">
                    <p className="inline-flex items-center pt-6 leading-relaxed">
                      <span className="text-grey-900 text-base">Read More </span>{' '}
                      <FaEllipsisH className="ml-1 -mb-2" />
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/content/projects/*.md" } }
      sort: { order: ASC, fields: frontmatter___sequence }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            author
            keywords
            extract
            reviewed
            cover {
              path {
                childImageSharp {
                  fluid(maxWidth: 500, quality: 100) {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                  }
                }
              }
              desc
            }
          }
        }
      }
    }
  }
`;
